import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './utils/utils'
import versionTood from './utils/versionUpdate'
// import axios from 'axios'
import wechat from './utils/wechat'
import my from './assets/js/lbc.js'
import 'amfe-flexible/index.min.js'
// import { createApp } from 'vue'
import {
  Cell, CellGroup, Col, Row, Button, Skeleton, Toast, Form, Field, Picker, Popup, Cascader, PullRefresh,
  Uploader, Card, Tag, NavBar, Icon, CountDown, Image as VanImage, List, NumberKeyboard, Dialog, Badge, Search,
  Swipe, SwipeItem, NoticeBar, Overlay, Loading, Empty, Divider, Checkbox, CheckboxGroup, SwipeCell, Stepper,
  GoodsAction, GoodsActionIcon, GoodsActionButton, SubmitBar, Tab, Tabs, AddressList, Switch, Sidebar, SidebarItem,
  Sticky, Popover, DropdownMenu, DropdownItem, ActionSheet, RadioGroup, Radio, Step, Steps, CouponCell, CouponList,
  PasswordInput, ImagePreview
} from 'vant'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.config.ignoredElements = ['wx-open-launch-weapp']

import { login_quick_sso } from './api/user'
// import VueClipboard from 'vue-clipboard2'
import clipboard from 'clipboard'
Vue.prototype.$my = my
Vue.config.productionTip = false
Vue.prototype.$versionTood = versionTood
var userAgent = navigator.userAgent
sessionStorage.setItem('secondCheck', 1)
if (userAgent.indexOf('MicroMessenger') > -1) {
  sessionStorage.setItem('userAgent', 'wechat')
  router.beforeEach((to, from, next) => {
    // 判断是否是线上环境
    if (process.env.NODE_ENV === 'production') { versionTood.isNewVersion() }
    const isLogin = (to) => { // 是否登录
      login_quick_sso().then(res => {
        console.log('is_openid', res.data.openIdIsExist)
        console.log('login_quick_sso', res.data)
        // 判断当前账号是否存在openid
        var is_openid = res.data.openIdIsExist
        if (is_openid) {
          sessionStorage.setItem('secondCheck', 0)
          // Vue.prototype.$utils.storeUserInfo(store, res.data); Vue.prototype.$utils.cSet('USER_INFO', res.data)
          var flag = false
          for (const i in res.data.categoryId) { if (res.data.categoryId[i] === '1') { flag = true } }
          if (flag) {
            if (res.data.certificationType !== 1) {
              router.replace('/home/preCertification')
            } else {
              console.log('to.fullPath', to.fullPath)
              if (to.fullPath === '/') { router.replace('/home/index') } else { router.replace(to.fullPath) }
              sessionStorage.setItem('liveFlag', 1)
              sessionStorage.setItem('liveFlag_cart', 1)
            }
          } else { router.replace('/user/error') }
        } else {
          console.log('login_quick_sso no openid')
          // 记录当前进入页面 然后进入登录流程 获取openid 重新登录
          sessionStorage.setItem('shareUrl', to.fullPath === '/' ? '/home/index' : to.fullPath)
          Vue.prototype.$utils.cDel('USER_INFO'); store.dispatch('user/clear'); store.dispatch('shopping/clear'); localStorage.clear(); sessionStorage.clear()
          router.replace('/user/login')
        }
      }).catch(() => {
        console.log('login_quick_sso error')
        sessionStorage.setItem('shareUrl', to.fullPath === '/' ? '/home/index' : to.fullPath)
        Vue.prototype.$utils.cDel('USER_INFO'); store.dispatch('user/clear'); store.dispatch('shopping/clear'); localStorage.clear(); sessionStorage.clear()
        router.replace('/user/login')
      })
    }
    /* 路由发生变化修改页面title */
    if (to.meta.title) { document.title = to.meta.title }
    // if (to.matched.some(record => record.meta.requireAuth) && !flag) {
    if (to.matched.some(record => record.meta.requireAuth)) {
      var secondCheck = sessionStorage.getItem('secondCheck')
      console.log('secondCheck', secondCheck, typeof secondCheck)
      if (secondCheck && parseInt(secondCheck) === 1) {
        console.log('isLogin true')
        isLogin(to)
      } else { next() }
    } else { next() }
  })
} else if (userAgent.indexOf('AlipayClient') > -1) {
  sessionStorage.setItem('userAgent', 'zhifubao')
} else {
  sessionStorage.setItem('userAgent', 'h5')
}
Vue.prototype.$utils = utils
Vue.prototype.$wechat = wechat
Vue.prototype.$clipboard = clipboard
Vue.use(VueAwesomeSwiper)
// Vue.use(VueClipboard)
// vant组件
Vue.use(Cell); Vue.use(CellGroup); Vue.use(Col); Vue.use(Row); Vue.use(Skeleton); Vue.use(Button); Vue.use(Toast); Vue.use(Form)
Vue.use(Field); Vue.use(Picker); Vue.use(Popup); Vue.use(Cascader); Vue.use(NavBar); Vue.use(Icon); Vue.use(CountDown); Vue.use(VanImage)
Vue.use(PullRefresh); Vue.use(Uploader); Vue.use(Card); Vue.use(Tag); Vue.use(Step); Vue.use(Steps); Vue.use(CouponCell); Vue.use(CouponList)
Vue.use(List); Vue.use(PasswordInput); Vue.use(NumberKeyboard); Vue.use(Dialog); Vue.use(Badge); Vue.use(Search); Vue.use(Swipe); Vue.use(SwipeItem)
Vue.use(NoticeBar); Vue.use(Overlay); Vue.use(Loading); Vue.use(Empty); Vue.use(Divider); Vue.use(Checkbox); Vue.use(CheckboxGroup); Vue.use(SwipeCell)
Vue.use(Stepper); Vue.use(GoodsAction); Vue.use(GoodsActionButton); Vue.use(GoodsActionIcon); Vue.use(SubmitBar); Vue.use(SubmitBar); Vue.use(Tab)
Vue.use(Tabs); Vue.use(AddressList); Vue.use(Switch); Vue.use(Sidebar); Vue.use(SidebarItem); Vue.use(Sticky); Vue.use(Popover); Vue.use(DropdownMenu)
Vue.use(DropdownItem); Vue.use(ActionSheet); Vue.use(Radio); Vue.use(RadioGroup); Vue.use(ImagePreview)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
